import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/redux/rootReducer';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus, ResponseStatus, UrlAPI } from 'src/shared/api/types';
import { wait } from 'src/shared/lib/async';
import { toDenominatorDateFormat } from 'src/shared/lib/date';
import { promisesTG } from 'src/shared/lib/type-guards/promises';
import { actionsNotifications } from '../../../../app/providers/NotificationsProvider/_BLL/notifications/slice';
import { actionsDashboard } from '../dashboard/slice';
import { StatusType } from '../dashboard/types/types';
import { Company, CompanyAddUpdatePayload, CompanyError } from './types/types';
import { CompanyAddUpdateREQBody, CompanyPeriodAddREQBody } from './types/types_REQ';
import { CompanyDelete_RES } from './types/types_RES';

const NAME = 'dashboard_company';

// * Thunks
const companyCreate = createAsyncThunk(`${NAME}/companyCreate`, async (arg: CompanyAddUpdateREQBody, thunkAPI) => {
	const { payload } = arg;

	return await rtkApiRequest.rtkPOSTRequest<Company>({
		url: UrlAPI.company,
		payload,
		thunkAPI,
	});
});

const companyUpdate = createAsyncThunk(`${NAME}/companyUpdate`, async (arg: CompanyAddUpdateREQBody, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { payload, options } = arg;

	const payloadsWithProofContent: CompanyAddUpdatePayload[] = []; // Proof content uploads independently. (Proof)
	const restRequest: CompanyAddUpdatePayload = {
		...payload,
		properties: {},
	};

	Object.entries(payload.properties).forEach(([propertyKey, propertyValue]) => {
		const proofContent = propertyValue && Object.values(propertyValue).find(property => property.proofContent);

		if (proofContent) {
			payloadsWithProofContent.push({
				...payload,
				properties: {
					[propertyKey]: propertyValue,
				},
			});
		} else {
			restRequest.properties[propertyKey] = propertyValue;
		}
	});

	const payloads = [...payloadsWithProofContent];
	if (Object.keys(restRequest.properties).length) {
		payloads.push(restRequest);
	}

	const rejected: CompanyError[] = [];

	// * Uploading data
	const responses = [];

	for (let i = 0; i < payloads.length; i++) {
		const response = rtkApiRequest
			.rtkPOSTRequest<Company>({
				url: UrlAPI.company,
				payload: payloads[i],
				thunkAPI,
				displayNotifications: false,
			})
			.then(res => {
				return {
					res,
					updateTime: new Date().getTime(),
				};
			})
			.catch(error => {
				Object.keys(payloads[i].properties).forEach(propertyName => {
					rejected.push({
						propertyName,
						error: error.payload,
					});
				});
			});

		responses.push(response);
	}

	await Promise.allSettled(responses).then(res => {
		let lastResponse: PromiseFulfilledResult<{ res: Company; updateTime: number }> | null = null;

		for (const response of res) {
			if (promisesTG.isPromiseFulfilled(response)) {
				if (lastResponse === null || (lastResponse && response.value.updateTime > lastResponse.value.updateTime)) {
					lastResponse = response;
				}
			}
		}

		if (lastResponse) {
			let companyToStore = lastResponse.value.res;

			if (options?.refreshOnlyUpdatedDatapoints) {
				const updatedDataPointsKeys = Object.keys(payload.properties);

				const properties = {};
				updatedDataPointsKeys.forEach((propertyKey: string) => {
					Object.assign(properties, { [propertyKey]: payload.properties[propertyKey] });
				});

				companyToStore = {
					...companyToStore,
					properties: {
						...companyToStore.properties,
						...properties,
					},
				};
			}

			dispatch(actionsDashboardCompany.companyStore(companyToStore)); // Get all datapoints to use in fields: sources, links, document links and a loooot more
			dispatch(actionsDashboard.generateMenuItemsWithProgressAndPriority()); // Gets names and other info about menu items on the left side of dashboard page
			dispatch(actionsDashboard.getCompanyOverview({ selectedCompanyId: companyToStore.id }));
			dispatch(
				actionsNotifications.addNotification({
					type: 'success',
					message: 'Company updated successfully',
				}),
			);
		}
	});

	if (rejected.length > 0) {
		dispatch(actionsDashboardCompany.setErrors(rejected));

		dispatch(
			actionsNotifications.addNotification({
				type: 'warning',
				message: 'Some data points were not updated',
			}),
		);
	} else {
		dispatch(actionsDashboardCompany.setErrors([]));
	}
});

const companyAddPeriod = createAsyncThunk(`${NAME}/companyAddPeriod`, async (payload: CompanyPeriodAddREQBody, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { reportingPeriodId, id } = payload;

	await rtkApiRequest.rtkPOSTRequest({
		url: UrlAPI.company,
		payload,
		thunkAPI,
	});

	dispatch(actionsDashboard.initialize({ companyId: id, reportingPeriodId }));
	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Period added successfully',
		}),
	);
});

const companyDelete = createAsyncThunk(`${NAME}/companyDelete`, async (payload: { companyId: number }, thunkAPI) => {
	const { dispatch } = thunkAPI;

	let status: ResponseStatus | null = null;
	let response: CompanyDelete_RES | null = null;

	const params = {
		id: payload.companyId,
	};

	do {
		const res: CompanyDelete_RES = await rtkApiRequest.rtkDELRequest<CompanyDelete_RES>({
			url: UrlAPI.company,
			params,
			thunkAPI,
		});

		status = res.status;

		if (res?.queryId) {
			Object.assign(params, { query_id: res.queryId });
			dispatch(actionsDashboardCompany.setDeleteQueryId(res.queryId));
			await wait(2000);
		}

		if (res.status === 'Finished') {
			response = res;
			dispatch(actionsDashboardCompany.setDeleteQueryId(null));
		} else if (res.status === 'Cancelled') {
			dispatch(actionsDashboardCompany.setDeleteQueryId(null));
			dispatch(
				actionsNotifications.addNotification({
					type: 'info',
					message: 'Company deletion cancelled',
				}),
			);
		}
	} while (status === 'InProgress');

	return response;
});

const cancelDelete = createAsyncThunk(`${NAME}/cancelDelete`, async (params: { query_id: number }, thunkAPI) => {
	return rtkApiRequest.rtkPOSTRequest({
		url: `${UrlAPI.company}/cancelDelete`,
		params,
		thunkAPI,
	});
});

export const researched = createAsyncThunk(`${NAME}/researched`, async (payload: { researchedType: string }, thunkAPI) => {
	const { researchedType } = payload;
	const { getState, dispatch } = thunkAPI;

	const state = getState() as RootState;
	const id = state.dashboardCompany.currentCompany?.id;
	const reportingPeriodId = state.dashboardCompany.currentCompany?.reportingPeriodId;
	const compLastResearchTime = state.dashboardCompany.currentCompany?.properties.compLastResearchTime;
	const compLastResearchedBy = state.dashboardCompany.currentCompany?.properties.compLastResearchedBy;
	const compResearchedType = state.dashboardCompany.currentCompany?.properties.compResearchedType;
	const name: string | undefined = state.auth.userData?.displayName;

	const date = toDenominatorDateFormat(new Date());

	const body = {
		id,
		reportingPeriodId,
		properties: {
			compLastResearchedBy: {
				...compLastResearchedBy,
				dataPoint: {
					...compLastResearchedBy!.dataPoint,
					value: name,
					status: StatusType.FILLED,
				},
			},

			compLastResearchTime: {
				...compLastResearchTime,
				dataPoint: {
					...compLastResearchTime!.dataPoint,
					value: date,
					status: StatusType.FILLED,
				},
			},

			compResearchedType: {
				...compResearchedType,
				dataPoint: {
					...compResearchedType!.dataPoint,
					value: researchedType,
					status: StatusType.FILLED,
				},
			},
		},
	};

	const res = await rtkApiRequest.rtkPOSTRequest<Company>({
		url: UrlAPI.company,
		payload: body,
		thunkAPI,
	});
	dispatch(actionsDashboardCompany.companyStore(res));
	id && dispatch(actionsDashboard.getCompanyOverview({ selectedCompanyId: id }));
	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Research info is updated',
		}),
	);
});

// * Reducer
interface State {
	currentCompany: Company | null;
	deleteQueryId: number | null;
	companyErrors: CompanyError[];
	status: RequestStatus;
}

export const initialState: State = {
	currentCompany: null,
	deleteQueryId: null,
	companyErrors: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		companyStore: (state, action: { payload: Company }) => {
			state.currentCompany = action.payload;
		},
		setDeleteQueryId: (state, action: PayloadAction<number | null>) => {
			state.deleteQueryId = action.payload;
		},
		setErrors: (state, action: { payload: CompanyError[] }) => {
			state.companyErrors = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(companyCreate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(companyCreate.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(companyCreate.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(companyUpdate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(companyUpdate.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(companyUpdate.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(companyAddPeriod.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(companyAddPeriod.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(companyAddPeriod.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(companyDelete.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(companyDelete.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(companyDelete.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(cancelDelete.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(cancelDelete.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(cancelDelete.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(researched.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(researched.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(researched.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsDashboardCompany = {
	...slice.actions,
	companyCreate,
	companyUpdate,
	companyAddPeriod,
	companyDelete,
	cancelDelete,
	researched,
};
