import { rtkApiRequest } from 'src/shared/api/api';
import { FetchBase, ResponseStatus } from 'src/shared/api/types';
import { ValueOf } from '../../type-guards/valueOf';
import { wait } from '../../async';

type Request = ValueOf<typeof rtkApiRequest>;

export const handleLongQuery = async <RES extends { queryId: number; status: ResponseStatus }>({
	arg,
	request,
	setQueryId,
	onFinish,
	onCancelled,
}: {
	arg: FetchBase & { thunkAPI: any };
	request: Request;
	setQueryId: (payload: number | null) => {
		payload: number | null;
		type: string;
	};
	onFinish: (res: RES) => void;
	onCancelled?: (res: RES) => void;
}): Promise<RES | null> => {
	const { dispatch } = arg.thunkAPI;

	let status: ResponseStatus | null = null;
	let response: RES | null = null;

	const requestArgs = {
		...arg,
	};

	do {
		const res = await request<RES>({
			...arg,
		});

		status = res.status;

		if (res?.queryId) {
			Object.assign(requestArgs, { params: { ...(arg.params && arg.params), query_id: res.queryId } });
			dispatch(setQueryId(res.queryId));
			await wait(2000);
		}

		if (res.status === 'Finished') {
			response = res;
			dispatch(setQueryId(null));
			onFinish(res);
		} else if (res.status === 'Cancelled') {
			dispatch(setQueryId(null));
			onCancelled && onCancelled(res);
		}
	} while (status === 'InProgress');

	return response;
};
