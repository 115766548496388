import { PropertiesCompany } from '../../company/types/types';
import { DataOnlyDictionary, Dictionaries } from '../../../../../app/redux/slices/metaDictionaries/types';

export type PersonRole =
	| '0' // No role
	| '1' // Executive
	| '2' // Board
	| '3'; // Both

// companyOverview response...
export interface Header {
	CompAnnualReportDate: string;
	CompDEIReportDate: string; // format: 'YYYY MM DD'
	CompSustainabilityReportDate: string; // format: 'YYYY MM DD'
	CompAnnualReportLink: string;
	CompDEIReportLink: string;
	CompSustainabilityReportLink: string;
	CompAccessibilityWebsite: string;
	CompLEI: string;
	CompLastUpdatedBy: string;
	CompLastPushedBy: string;
	CompLastResearchedBy: string;
	CompDEIID: string;
	CompLegalName: string;
}

export interface CompanyOverview {
	availableReportingPeriods: number[];
	newInSilver: string;
	rating: string;
}

export interface CompanyOverviewRes {
	header: Header;
	overview: CompanyOverview;
}
// ...companyOverview response

export type DashboardMetaEntityName = 'company' | 'person';

export type DashBoardMenuOptions = 'Cover' | CompanyMenuOptions | PeopleMenuOptions;

export type CompanyMenuOptions =
	| 'General'
	| 'Identification'
	| 'Ownership'
	| 'Location'
	| 'Organizational'
	| 'Stakeholders'
	| 'Policy'
	| 'Race'
	| 'Gender'
	| 'Education'
	| 'Family'
	| 'Age'
	| 'Health'
	| 'Disabilities'
	| 'Sexuality'
	| 'Religion'
	| 'Politics';

export type PeopleMenuOptions = 'Board of Directors' | 'Executives';

// Meta data
export interface MetaEntity {
	entityName: DashboardMetaEntityName;
	tabs: TabMeta[];
}

export interface TabMeta {
	name: DashBoardMenuOptions;
	showInHeader: boolean;
	isExecutiveGroup: boolean;
	isBoardGroup: boolean;
	groups: MetaGroup[];
	properties?: PropertyMeta[];
}

export interface MetaGroup {
	name: MetaGroupNames;
	properties: PropertyMeta[];
}

export type PropertyHandlingType = 'normal' | 'sectorIndustry' | 'target' | 'deltaPercentage' | 'deltaPercentageEthnic' | 'yearAndMonth';

export type PropertyTag = PriorityTag;

export type PriorityTag = 'Mandatory' | 'Highest Priority' | 'Second Highest Priority' | 'Third Highest Priority' | 'Lowest Priority';

export interface PropertyMeta {
	propertyName: string;
	displayName: string;
	fieldOrder: number;
	showInHeader: boolean;
	handlingType: PropertyHandlingType;
	tags: PropertyTag[] | null;
	description?: string;
	dataPoints: DataPointMeta[];
}

export type DataPointNameTarget = 'existence' | 'target' | 'deadline';

export type DataPointNameSectorIndustry = 'sector' | 'industry';

export type DataPointNameDeltaPercentage = 'delta' | 'percentage';

export type DataPointName = 'dataPoint' | DataPointNameTarget | DataPointNameSectorIndustry | DataPointNameDeltaPercentage;

export type CustomLogic = 'If countries.states != null filedType = DropDown, else filedType = String' | 'filledOnce';

export interface DataPointMeta {
	internalName: DataPointName;
	fieldType: FieldType;
	customLogic: CustomLogic | null;
	isEditable: boolean;
	allowsNull: boolean;
	allowsMany: boolean;
	isUAStatusAllowed: boolean;
	isValueLevelAllowed: boolean;
	measureUnits?: MeasureUnits;
	rangeLow?: number;
	rangeHigh?: number;
	options?: string[];
	dictionary?: keyof Dictionaries;
}

export type MetaGroupNames = string;

export type FieldType =
	| 'Custom'
	| 'Integer'
	| 'Id'
	| 'IntegerWithDeadline'
	| 'Float'
	| 'FloatDetailed'
	| 'FloatWithDeadline'
	| 'String'
	| 'Boolean'
	| 'BooleanDropDown'
	| 'BooleanWithDeadline'
	| 'Percentage'
	| 'PercentageWithDeadline'
	| 'DropDown'
	| 'DictionaryArray'
	| 'Date'
	| 'YearAge'
	| 'AgeFloat'
	| 'Currency';

export type MeasureUnits = '%' | 'weeks' | 'h' | 'year' | 'years' | 'cm' | 'kg' | 'USD' | 'number';

export type PropertiesREQ = Partial<PropertiesCompany>;

export interface CommonProperty {
	dataPoint: DataPoint;
}

export interface IndustryAndSectorProperty {
	industry: DataPoint;
	sector: DataPoint;
}

export interface TargetProperty {
	existence: DataPoint;
	target: DataPoint;
	deadline: DataPoint;
}

export interface DeltaProperty {
	delta: DataPoint;
	percentage: DataPoint;
}

export type Property = CommonProperty | IndustryAndSectorProperty | TargetProperty | DeltaProperty;

export type DataPointValue = number | string | 'true' | 'false' | null;
export type DataPointValues = string[] | null;
export type DataPointSource = number | null;
export type DataPointSourceUrl = string | null;
export type ReferenceDate = string | null;

export type DataPointBooleanValue = Exclude<DataPointValue, number | string>;

export interface ProofContent {
	content: string | null; // Pure base64 without data and MIME types
	constType: string | null; //MIME type
}

export enum StatusType {
	FILLED = 'Filled',
	EMPTY = 'Empty',
	UNKNOWN = 'Unknown',
}

// TODO: Make 2 different types DataPointSingle (value) | DataPointMultiple (values)
export interface DataPoint {
	value?: DataPointValue;
	values?: DataPointValues;
	type: number;
	source: DataPointSource;
	sourceComment: string | null;
	sourceDocumentId: number | null;
	sourceDocumentName: string | null;
	sourceDocumentUrl: string | null;
	sourceTag: any | null;
	sourceUrl: DataPointSourceUrl;
	pageNumber: number | null;
	proofUrl: string | null; // Link to proof image, received from request.
	locationInProof: string | null; // Extra information on proof. Example: Page number
	proofContent: ProofContent | null; // Always null when the people are received.
	status: StatusType;
	verified: boolean;
	verifiedBy: string | null; // User name.
	verifiedAt: string | null; // Date string.
	confidence: number | null; // number value range: 0 - 1.
	inputKind: number;
	valueLevel: number | null;
	referenceDate: ReferenceDate;
	dataProcessingType: number | null;
}

// Misc
export interface SingleMenuItem {
	name: DashBoardMenuOptions;
	filled: number | null;
	unknown: number | null;
	totalValue: number | null;
	priority: Priority;
}

export interface GeneratedField {
	namePrefix: string;
	propertyName: string;
	internalName: string; // TODO: type internalName
	disabled: boolean;
	validate?: ValidateFunction;
	measureUnits?: MeasureUnits;
	fieldWidth?: string;
	handlingType?: PropertyHandlingType;
	fieldDictionary?: FieldDictionary;
	customLogic?: CustomLogic | null;
}

export type FieldDictionary = DataOnlyDictionary[keyof DataOnlyDictionary];

export type ValidateFunction = (value: string | null) => undefined | string;

export type Priority = { [key in PriorityTag]: { filled: number; unknown: number; total: number } };

export interface PriorityTab {
	name: DashBoardMenuOptions;
	priority: Priority;
}

export interface TeamNote {
	companyId: number;
	id: number;
	text: string;
	timestamp: string; // UTC Example: '2023-08-29T08:45:38.9576634Z'
	userDisplayName: string;
	userId: number;
}

export interface CompanyDocuments {
	modalUpdate: boolean;
}

// * REQ/RES
export interface GetTeamNotesREQ {
	params: {
		company_id: number;
	};
}

export type GetTeamNotesRES = TeamNote[];

export interface PostTeamNotesREQ {
	payload: {
		companyId: number;
		text: string;
	};
}

export type PostTeamNotesRES = TeamNote[];

export interface PutTeamNotesREQ {
	payload: {
		id: number; // note id
		text: string;
	};
}

export type PersonPropertyKeys =
	| 'baseSalary'
	| 'birthYear'
	| 'boardChair'
	| 'boardTitle'
	| 'children'
	| 'companyId'
	| 'disability'
	| 'educationInstitution'
	| 'educationLevel'
	| 'educationSubject'
	| 'execCEO'
	| 'execCSuite'
	| 'execCSuiteTitle'
	| 'execTitle'
	| 'gender'
	| 'jobTenureCompanyStartYear'
	| 'jobTenureTotalStartYear'
	| 'married'
	| 'name'
	| 'nationality'
	| 'otherEducationInstitution'
	| 'otherEducationLevel'
	| 'otherEducationSubject'
	| 'otherIncentive'
	| 'personId'
	| 'previousExperience'
	| 'race'
	| 'religion'
	| 'roleType'
	| 'sexuality'
	| 'urban';

export type DataPointKeys =
	| 'confidence'
	| 'dataProcessingType'
	| 'inputKind'
	| 'locationInProof'
	| 'proofContent'
	| 'proofUrl'
	| 'referenceDate'
	| 'source'
	| 'sourceUrl'
	| 'status'
	| 'type'
	| 'value'
	| 'verified'
	| 'verifiedAt'
	| 'verifiedBy';
